.box-container {
  height: auto;
  width: 100%;
  /* background-color: rgb(230, 237, 237); */
  background-color: rgb(242, 242, 242);
  margin-top: 4px;
  /* position: relative; */
  /*display: flex;
  flex-direction: column; */
}

.outer-box-container {
  height: 34%;
  width: 100%;
  /* background-color: rgb(177, 237, 217); */
  /* position: absolute; */
  display: flex;
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
  position: relative;

}

.box {
  flex: 1;
  padding: 16px;
  /* border: 3px solid rgb(233, 226, 226); */
  border-radius: 80px 80px 0px 0px;
  /* background-color: rgba(96, 207, 222, 0.942); */
  height: 170px;
  margin: 4px;
  display: flex;
  justify-content: center;
  position: relative;
  /* align-items: flex-end; */

}

.circle {
  width: 120%;
  height: 78%;
  /* background-color: rgba(28, 181, 201, 0.942); */
  background-color: #00AEEF;
  border-radius: 50%;
  border: 1px solid rgb(233, 226, 226);
  align-items: center;
  justify-content: center;
  display: flex;

}

.h7 {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
}

.words {
  font-weight: bold;
  font-style: inherit;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: larger;
  /* text-shadow: 2px 2px 4px #000000; */
  color: rgb(255, 255, 255);
}

.word1,
.word2,
.word3 {
  position: absolute;
  bottom: 5px;
  left: 16px;
  right: 16px;
  text-align: center;
  font-size: 16px;
  font-weight: normal;
  overflow-wrap: break-word;
  /* Allow word wrapping */
  line-height: 18px;
  /* text-shadow: 2px 2px 4px #000000; */
  color: rgb(0, 0, 0);

}

.history {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: medium;
  font-weight: 500;
  margin-left: 10px;
  margin-top: 18px;
  /* position: absolute; */
  color: rgb(44, 98, 99);

}

@media (max-width: 450px) {
  .circle {
    width: 128%;
    height: 78%;
  }

  .words {
    font-size: medium;
  }

  .box {
    height: 150px;
    width: 100%;
  }
}

@media (max-width: 430px) {
  .circle {
    width: 140%;
    height: 72%;
  }

  .words {
    font-size: medium;
  }

  .box {
    height: 146px;
    width: 100%;
  }


}

@media (max-width: 400px) {
  .circle {
    width: 140%;
    height: 72%;
  }

  .words {
    font-size: medium;
  }

  .box {
    height: 140px;
  }

}


@media (max-width: 383px) {
  .circle {
    width: 160%;
    height: 68%;
  }

  .box {
    height: 136px;
  }

  .words {
    font-size: small;
  }

  .history {
    font-size: small;
  }
  .word1,
  .word2 {
    font-size: small;
  }

}

@media (max-width: 365px) {
  .circle {
    width: 180%;
    height: 72%;
  }

}

@media (max-width: 365px) {
  .outer-box-container {
    width: 100%;

  }

  .word1,
  .word2 {
    font-size: small;
  }

  .box {
    height: 126px;
  }

  .words {
    font-size: small;
  }

  .history {
    font-size: small;
  }

}

@media (max-width: 360px) {
  .outer-box-container {
    width: 100%;

  }

  .word1,
  .word2 {
    font-size: small;
  }

  .box {
    height: 126px;
  }

  .words {
    font-size: small;
  }

  .history {
    font-size: small;
  }

  .circle {
    width: 180%;
    height: 72%;
  }

}