/* .form-container1 {
    height: 670px;
    width: 499px;
    background-color: #ffffff;
    background-image: url("images/stat17.jpg") ;
    position: relative;
    
}

.myprofile {
    font-size:xx-large;
    font-weight: bold;
    margin-left: 16px;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.first-container {
    width: 480px;
    height: 450px;
    background-color: rgb(244, 244, 238);
    position: relative;
    margin: 0 auto;
    margin-top: 50px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 3px 1px 0 rgba(0, 0, 0, 0.19);
}

.first-container-1 {
    width: 460px;
    height: 380px;
    background-color: white;
    margin: 0 auto;
    position:absolute;
    top: 60px;
    left: 9px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2), 0 4px 6px 0 rgba(0, 0, 0, 0.19);

}

.second-container {
    width: 480px;
    height: 112px;
    margin: 0 auto;
    margin-top: 8px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 3px 1px 0 rgba(0, 0, 0, 0.19);
    position: relative;
    display: flex;
    background-color:rgb(244, 244, 238);
    

}

.c2 {
    width: 20%;
    height: 80%;
    background-color: antiquewhite;
    border-radius: 50%;
    position: absolute;
    top: 11px; 
    left: 2%; 
    overflow: hidden;

}

.r-officer {
    position: absolute;
    top: 38%;
    right: 22%;
    font-size:larger;
    font-weight:bold;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;

}

.circle-image2 {
    width: 100%;
    height: 100%;

}

.info-row {
    text-align: left;
    margin-bottom: 10px;
}

.info-row1 {
    margin-top: 4%;
    margin-left: 4%;
    
}

.label1 {
    font-weight: bold;
    font-family: 'Times New Roman', Times, serif;
    font-style:oblique; 
    font-size:large;
}

.namehead {
    position: absolute;
    top: 3%;
    left: 72%;
} */

.full-container {
  height: 100%;
  width: 100%;
  background-color: rgb(243, 242, 242);
  position: relative;
  /* padding-top: 60%; */
}

.upper-part {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 28%;
  background-color: #1976d2;
}

.divider-box {
  position: absolute;
  width: 87%;
  height: 25%;
  background-color: rgb(255, 255, 255);
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  /* box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.19); */
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 0;
  padding-right: 0;
}

.profile {
  font-size: xx-large;
  font-weight: bold;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  position: absolute;
  top: 10%;
  /* Adjust the vertical position as needed */
  left: 50%;
  transform: translateX(-50%);
  color: white;
}

.img-box {
  width: 25%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
  margin-bottom: 6px;
  border-radius: 8px;
  border: 1px solid #949494;
  overflow: hidden;
}

.hash {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.eight-boxes .b {
  width: 92%;
  /* Adjust the width and margin as needed */
  margin-bottom: 4px;
  /* Add margin to create space between boxes */
  /* Add margin to create space between boxes */
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 5px;
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); */
  text-align: left;
  display: flex;
  height: 62px;
  flex-direction: column;
}

.eight-boxes .b-1 {
  width: 92%;
  /* Adjust the width and margin as needed */
  margin-bottom: 4px;
  /* Add margin to create space between boxes */
  margin-right: 20px;
  /* Add margin to create space between boxes */
  padding: 10px;
  background-color: #fdfdfd;
  border: 1px solid #ccc;
  border-radius: 5px;
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); */
  text-align: left;
  display: flex;
  flex-direction: column;
  height: 165px;
}

.row-container {
  display: flex;
  width: 97%;
  gap: 5px; /* Reduced spacing between cards */
}

.mobile-card,
.email-card {
  flex: 1; /* Ensure they take equal space */
  padding: 5px; /* Reduced padding for smaller space */
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.mobile-card {
  max-width: 45%; /* Adjust this as needed to reduce width */
}

.email-card {
  max-width: 48.5%; /* Adjust this as needed to reduce width */
}

.row-container {
  display: flex;
  width: 97%;
  gap: 5px; /* Reduced spacing between cards */
}

.mobile-card,
.email-card,
.recruitment-card,
.permanent-card {
  flex: 1; /* Equal width for all cards */
  padding: 10px; /* Adjust padding */
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.recruitment-card,
.permanent-card {
  max-width: 46.5%; /* Adjust this value to control width */
}

/* Optional: Remove unnecessary left margin */
.email-card,
.permanent-card {
  margin-left: 0;
}

.reporting-officer-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px; /* Space between name and image */
  margin-bottom: 10px; /* Add space below the name and image */
}

/* Styling for the Report Officer title */
.report-officer-title {
  margin-right: 10px;
  font-size: 18px;
  /* font-weight: bold; */
}

/* Styling for the Reporting Officer's image */
.reporting-officer-img img {
  width: 70px; /* Adjust the size as needed */
  height: 80px; /* Adjust the size as needed */
  margin-top: -20px;
  margin-left: 90px;
  margin-bottom: -50px;
}

/* Styling for the Reporting Officer's name text */
.reporting-officer-name p {
  font-size: 16px;
  margin: 0;
}

/* Styling for the Reporting Officer's name text */
.reporting-officer-name p {
  font-size: 16px;
  margin: 0;
  padding-right: 10px;
}

.b h2 {
  font-size: medium;
  color: rgb(81, 81, 81);
}

.b-1 h2 {
  font-size: medium;
  color: rgb(209, 74, 74);
}

.b p {
  font-weight: bold;
  font-size: small;
}

.b-1 p {
  font-weight: bold;
  font-size: small;
}

.eight-boxes {
  position: absolute;
  top: 40%;
  right: -1.5%;
  display: flex;
  flex-wrap: wrap;
  /* Ensures the boxes wrap to the next line if they don't fit horizontally */
  justify-content: space-between;
  /* Creates space between the boxes */
  width: 95%;
  padding-left: 0;
  padding-right: 0;
}

.eight-boxes .b h2,
.eight-boxes .b p,
.eight-boxes .b-1 h2,
.eight-boxes .b-1 p {
  margin: 0;
  /* Remove default margin */
  padding: 1px 0;
  /* Add padding to create space between h2 and p */
}

.eight-boxes .b p,
.eight-boxes .b-1 p {
  white-space: nowrap;
  /* Prevent text from wrapping */
  overflow: hidden;
  /* Hide overflowing text */
  text-overflow: ellipsis;
  /* Add ellipsis (...) to indicate text overflow */
}

.us {
  font-weight: 700;
  font-size: large;
}

@media (max-width: 1200px) {
  .full-container {
    width: 100%;
  }

  .img-box {
    width: 14%;
  }

  .divider-box {
    width: 65%;
  }
}

@media (max-width: 1024px) {
  .img-box {
    width: 16%;
    height: 60%;
  }

  .divider-box {
    width: 65%;
  }
}

@media (max-width: 767px) {
  .full-container {
    width: 100%;
  }

  .img-box {
    width: 22%;
    height: 60%;
  }

  .divider-box {
    width: 85%;
  }
}

@media (max-width: 480px) {
  .full-container {
    width: 100%;
  }

  .divider-box {
    width: 87%;
  }

  .img-box {
    width: 24%;
    height: 60%;
  }
}

/* @media (max-width: 767px) {
    .eight-boxes .b {
        width: 82%;
    }
    
} */
